import { computed, createApp, reactive, ref, watch } from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { DateTime, Duration, Settings, Interval } from 'luxon';
import Tooltip from 'bootstrap/js/dist/tooltip';
import Offcanvas from 'bootstrap/js/dist/offcanvas';
import Dropdown from 'bootstrap/js/dist/dropdown';
import Collapse from 'bootstrap/js/dist/collapse';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../src/css/main.pcss';
import {
  scrollToSmoothly,
  inflectNumber,
  isLoggedIn,
  countClicks,
  openLoginModal,
  initializeScrollbar,
  observeEmbedded,
  lazyLoad,
  toggleBackdrop,
  addInternalNotification,
  getCookieValue,
  countVisit,
  initializeClickableElements,
  saveScrollPositionState,
} from '../assets/js/utils';
import vClickOutside from 'click-outside-vue3';
import { io } from 'socket.io-client';
import { envConfig } from '../env.config.js';
import pages from './pages/pages.js';
import sections from './sections/sections.js';
import components from './components/components.js';
import { createPinia } from 'pinia';
import { useBetStore } from './stores/BetStore.js';
import { useCouponStore } from './stores/CouponStore.js';
import { useUserStore } from './stores/UserStore.js';
import { useSettingsStore } from './stores/SettingsStore.js';
import { useAdminStore } from './stores/AdminStore.js';
import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbars, ClickScrollPlugin } from 'overlayscrollbars';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { Notice } from '../assets/js/Notice';
import mitt from 'mitt';

if (window.safari === undefined) { // not Safari
  document.documentElement.setAttribute('data-overlayscrollbars-initialize', '');
  document.body.setAttribute('data-overlayscrollbars-initialize', '');
  OverlayScrollbars.plugin(ClickScrollPlugin);
  const osInstance = OverlayScrollbars({
    target: document.body,
    cancel: {
      nativeScrollbarsOverlaid: true,
    }
  }, {
    showNativeOverlaidScrollbars: true,
    update: {
      debounce: 0,
    },
    scrollbars: {
      autoHide: 'never',
      clickScroll: true,
    },
  });
}

Settings.defaultLocale = 'pl';

window.OverlayScrollbars = OverlayScrollbars;
window.WP = BACKEND_DATA.data;
window.axios = axios;
window.DateTime = DateTime;
window.Interval = Interval;
window.scrollToSmoothly = scrollToSmoothly;
window.inflectNumber = inflectNumber;
window.isLoggedIn = isLoggedIn;
window.openLoginModal = openLoginModal;
window.countClicks = countClicks;
window.initializeScrollbar = initializeScrollbar;
window.observeEmbedded = observeEmbedded;
window.lazyLoad = lazyLoad;
window.toggleBackdrop = toggleBackdrop;
window.getCookieValue = getCookieValue;
window.countVisit = countVisit;
window.initializeClickableElements = initializeClickableElements;
window.Tooltip = Tooltip;
window.Offcanvas = Offcanvas;
window.Collapse = Collapse;
window.webSocketsEnabled = envConfig.socketAddress !== '';
window.socket = undefined;
window.notifications = reactive([]);
window.originalTitle = document.title;
window.isFirebaseSupported = isSupported();

window.mainHeaderHeight = 66;
window.adminBarHeight = 32;

const emitter = mitt();

const firebaseApp = initializeApp(envConfig.firebaseConfig);
const messaging = navigator.serviceWorker ? getMessaging(firebaseApp) : null;

function generateToken() {
  return Math.random().toString(36).substring(2) + Date.now().toString(36);
}

window.browserToken = localStorage.getItem('ztBrowserToken');

if (!window.browserToken) {
  window.browserToken = generateToken();
  localStorage.setItem('ztBrowserToken', window.browserToken.toString());
}

window.getFirebaseToken = function () {
  // console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      // console.log('Notification permission granted.');
      getToken(messaging, {
        vapidKey: envConfig.vapidKey
      }).then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          // console.log(currentToken);
          axios.post(WP.ajaxUrl, {
            endpoint: 'api_save_notification_token',
            token: currentToken,
            browser_token: window.browserToken
          }).then(r => {
            // console.log(r.data.data)
          })
        } else {
          // Show permission request UI
          // console.log('No registration token available. Request permission to generate one.');
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    }
  });
};

// Is FCM supported
isSupported().then(() => {
  const isSafari = !!window.GestureEvent;

  if (!isSafari) {
    window.getFirebaseToken();
  } else {
    window.addEventListener('click', () => {
      window.getFirebaseToken();
    }, { once: true });
  }


  onMessage(messaging, (payload) => {
    let notificationTitle = '';
    let notificationOptions = '';
    let bottomLink = '';
    if (payload.data.title) {
      notificationTitle = payload.data.title;
      notificationOptions = {
        body: payload.data.body,
        icon: '/wp-content/themes/zawodtyper/assets/images/zt-logo.png',
      };
      if (payload.data.zt_content) {
        notificationOptions.body = payload.data.zt_content;
      }
      if (payload.data.bottomLink) {
        bottomLink = payload.data.bottomLink;
      }
    } else {
      notificationTitle = payload.notification.title;
      notificationOptions = {
        body: payload.notification.body,
        icon: '/wp-content/themes/zawodtyper/assets/images/zt-logo.png',
      };
    }
    addInternalNotification(new Notice()
      .setContent(notificationOptions.body)
      .setTitle(notificationTitle)
      .setBottomLink(bottomLink)
      .setDismissTime(5000));
  });


  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      for(let registration of registrations) {
        if (registration.scope === window.location.origin + '/' ) {
          registration.unregister().then(success => {
            if (success) {
              console.log('Service Worker został pomyślnie wyrejestrowany.');
            } else {
              console.log('Nie udało się wyrejestrować Service Workera.');
            }
          });
        }
      }
    });
  }
}).catch(() => {
  console.log('Brak wsparcia dla powiadomień');
})

let windowWidth = ref(window.innerWidth);
window.size = reactive({
  mobile: computed(() => windowWidth.value < 1280),
  desktop: computed(() => windowWidth.value >= 1280),
  width: computed(() => windowWidth.value),
});
window.addEventListener('resize', () => windowWidth.value = window.innerWidth);

let prevScrollPos = window.scrollY;
let headerY = 0; // zakres <-66; 0>
let mainHeaderElement = document.getElementById('main-header');
// let userMenuButton = document.getElementById('user-menu-button');
mainHeaderElement.addEventListener('mouseover', e => {
  e.composedPath().forEach(pathEl => {
    if (pathEl?.classList?.contains('menu-item-has-children')) {
      document.getElementById('header-backdrop')?.classList.remove('opacity-0');
    }
  });
});
mainHeaderElement.addEventListener('mouseout', e => {
  e.composedPath().forEach(pathEl => {
    if (pathEl?.classList?.contains('menu-item-has-children')) {
      document.getElementById('header-backdrop')?.classList.add('opacity-0');
    }
  });
});

let scrollY = ref(window.scrollY);
let scrollUp = ref(false);
window.scrollPos = reactive({
  y: computed(() => scrollY.value),
  movingUp: computed(() => scrollUp.value),
  movingDown: computed(() => !scrollUp.value),
});
window.addEventListener('scroll', e => {
  if (window.scrollEventHandlerBlocked) {
    return;
  }
  let currentScrollPos = scrollY.value = window.scrollY;

  if (prevScrollPos > currentScrollPos) { // ruch w górę
    scrollUp.value = true;

    if (window.size.mobile) {
      headerY = Math.min(headerY + (prevScrollPos - currentScrollPos), 0);
    }
    prevScrollPos = currentScrollPos;
  } else if (prevScrollPos < currentScrollPos) { // ruch w dół
    scrollUp.value = false;

    if (window.size.mobile && currentScrollPos > 10) {
      headerY = Math.max(headerY - (currentScrollPos - prevScrollPos), -window.mainHeaderHeight);
    }
    prevScrollPos = currentScrollPos;
  }

  if (window.size.mobile) {
    mainHeaderElement.style.transform = 'translateY(' + headerY + 'px)';
  }
}, { passive: true });

function snapHeaderToNearestEdge() {
  if (headerY !== 0 && headerY !== -window.mainHeaderHeight) { // jeśli nie znajduje się w skrajnym położeniu, dociągnij do bliższego
    headerY = headerY >= -(window.adminBarHeight + 1) ? 0 : -window.mainHeaderHeight;
    if (window.size.mobile) {
      mainHeaderElement.style.transform = 'translateY(' + headerY + 'px)';
    }
  }
  setTimeout(() => {
    mainHeaderElement.classList.remove('transition-transform');
  }, 150);
}
window.hideHeader = () => {
  headerY = -window.mainHeaderHeight;
  if (window.size.mobile) {
    mainHeaderElement.classList.add('transition-transform');
    mainHeaderElement.style.transform = 'translateY(' + headerY + 'px)';
    setTimeout(() => {
      mainHeaderElement.classList.remove('transition-transform');
    }, 150);
  }
};
window.blockScrollEventHandler = (scrollPos) => {
  window.scrollEventHandlerBlocked = true;
  if (scrollPos) {
    prevScrollPos = scrollPos;
  }
};
window.unblockScrollEventHandler = () => {
  prevScrollPos = window.scrollY;
  window.scrollEventHandlerBlocked = false;
};
window.addEventListener('touchend', e => {
  mainHeaderElement.classList.add('transition-transform');
  // setTimeout(snapHeaderToNearestEdge, 150);
});
window.addEventListener('load', () => {
  scrollY.value = window.scrollY;
});

if (window.webSocketsEnabled && !PHP.bot) {
  window.socket = io(envConfig.socketAddress, { secure: true, autoConnect: false });
  const sessionID = localStorage.getItem('ws_sessionID');
  let userData;
  if (sessionID) {
    userData = { sessionID };
  }

  if (WP.user.id !== 0) {
    userData = {
      ...userData,
      role: PHP.role,
      username: WP.user.name,
      blocked: 'false',
      wpUserId: WP.user.id,
    };
  }

  window.socket.auth = userData;
  window.socket.connect();

  window.socket.on('connect_error', (err) => {
    // if (err.message === "Invalid username") {
      console.log(err.message);
    // }
  });
  window.socket.on('session', (user) => {
    // attach the session ID to the next reconnection attempts
    window.socket.auth = { ...user, role: PHP.role };
    // store it in the localStorage
    localStorage.setItem('ws_sessionID', user.sessionID);
  });
}

window.createAndMountApp = function createAndMountApp(pageElements, sectionElements, componentElements) {
  for (let pageElement of pageElements) {
    let app;
    switch (pageElement.dataset.page) {
      case 'betting-tips':
        app = createApp(pages.BettingTips);
        break;
      case 'user-profile':
        app = createApp(pages.UserProfile);
        break;
      case 'user-list':
        app = createApp(pages.UserList);
        break;
      case 'settings':
        app = createApp(pages.Settings);
        break;
      case 'tournament-stats':
        app = createApp(pages.TournamentStats);
        break;
      case 'admin-panel':
        app = createApp(pages.AdminPanel);
        break;
			case 'search-page':
				app = createApp(pages.SearchPage, pageElement.dataset);
				break;
      case 'new-password-page':
        app = createApp(pages.NewPasswordPage);
        break;
      default:
        console.log('No matching Vue component: ' + pageElement.dataset.page);
        continue;
    }
    window.mountApp(app, pageElement);
  }

  for (let sectionElement of sectionElements) {
    let app;
    switch (sectionElement.dataset.section) {
      case 'daily-bets-profile':
        app = createApp(sections.DailyBetsProfile, sectionElement.dataset);
        break;
      case 'contact-form':
        app = createApp(sections.ContactForm, sectionElement.dataset);
        break;
      case 'best-bet-history':
        app = createApp(sections.BestBetsHistory, sectionElement.dataset);
        break;
      case 'winners-history':
        app = createApp(sections.WinnersHistory, sectionElement.dataset);
        break;
      case 'last-month-stats':
        app = createApp(sections.BestLastMonthStats, sectionElement.dataset);
        break;
      case 'best-bet-series':
        app = createApp(sections.BestBetSeries, sectionElement.dataset);
        break;
      case 'comments-bonuses':
        app = createApp(sections.CommentsBonuses, sectionElement.dataset);
        break;
      case 'fight-galas':
         app = createApp(sections.FightGalas, sectionElement.dataset);
         break;
      case 'zawodnicy':
        app = createApp(sections.Competitors, sectionElement.dataset);
        break;
      case 'polls':
        app = createApp(sections.Polls, sectionElement.dataset);
        break;
      case 'journalists':
        app = createApp(sections.Journalists, sectionElement.dataset);
        break;
      case 'gala-schedule':
        app = createApp(sections.GalaSchedule, sectionElement.dataset);
        break;
			case 'fight-schedule':
				app = createApp(sections.FightSchedule, sectionElement.dataset);
				break;
			case 'bets-discipline':
				app = createApp(sections.BetsDiscipline, sectionElement.dataset);
        break;
      case 'bookmakers-ranking':
        app = createApp(sections.BookmakersRanking, sectionElement.dataset);
        break;
      case 'current-month-best-bet-series':
        app = createApp(sections.CurrentMonthBestBetSeries, sectionElement.dataset);
        break;
      case 'best-bets-in-last-7-days':
        app = createApp(sections.BestBetsInLast7Days, sectionElement.dataset);
        break;
      case 'awarded-bets':
        app = createApp(sections.AwardedBets, sectionElement.dataset);
        break;
      case 'classified-in-top-10':
        app = createApp(sections.ClassifiedInTop10, sectionElement.dataset);
        break;
	    case 'bonus-without-deposits':
				app = createApp(sections.BonusWithoutDeposits, sectionElement.dataset);
				break;
	    case 'football-table':
				app = createApp(sections.FootballTable, sectionElement.dataset);
				break;
      case 'user-list-box':
        app = createApp(sections.UserListBox, sectionElement.dataset);
        break;
	    case 'football-league-results':
				app = createApp(sections.FootballLeagueResults, sectionElement.dataset);
				break;
	    case 'football-league-timetable':
				app = createApp(sections.FootballLeagueTimetable, sectionElement.dataset);
				break;
	    case 'football-compare-teams':
				app = createApp(sections.FootballStatsCompareTeams, sectionElement.dataset);
				break;
	    case 'football-all-football-tables':
				app = createApp(sections.FootballAllFootballTables, sectionElement.dataset);
				break;
	    case 'football-league-groups':
				app = createApp(sections.FootballLeagueGroups, sectionElement.dataset);
				break;
        case 'football-league-nested-groups':
				app = createApp(sections.FootballLeagueNestedGroups, sectionElement.dataset);
				break;
	    case 'football-league-best-global-scorers':
				app = createApp(sections.FootballLeagueBestGlobalScorers, sectionElement.dataset);
				break;
        case 'fifa-ranking':
                app = createApp(sections.FifaRankings, sectionElement.dataset);
                break;
      case 'user-rankings':
        app = createApp(sections.UserRankings, sectionElement.dataset);
        break;
      default:
        console.log('No matching Vue component: ' + sectionElement.dataset.section);
        continue;
    }
    window.mountApp(app, sectionElement.parentElement);
  }

  for (let componentElement of componentElements) {
    let app;
    switch (componentElement.dataset.component) {
      case 'comments':
        app = createApp(components.Comments, componentElement.dataset);
        break;
      case 'notification-panel':
        app = createApp(components.NotificationPanel);
        break;
      case 'login-modal':
        app = createApp(components.LoginModal);
        break;
      case 'klasyfikacja':
        app = createApp(components.RankingTable, componentElement.dataset);
        break;
      case 'kody_promocyjne_tabelka':
        app = createApp(components.PromoCodesTable);
        break;
      case 'najlepsi_typerzy':
        app = createApp(components.BestTypers, componentElement.dataset);
        break;
      case 'baner_najwyzszy_kurs':
        app = createApp(components.BannerYesterdayMaxOdd);
        break;
      case 'baner_najskuteczniejszy_typer':
        app = createApp(components.BannerBestAccuracy);
        break;
      case 'baner_typy_dnia':
        app = createApp(components.BannerTd);
        break;
      case 'baner_konkurs_rekord':
        app = createApp(components.BannerCompetitionRecord);
        break;
      case 'koszulka':
        app = createApp(components.BannerKoszulka, componentElement.dataset);
        break;
      case 'koszulka_wysuw':
        app = createApp(components.BannerKoszulkaWysuw, componentElement.dataset);
        break;
      case 'bet_redaktora':
        app = createApp(components.EditorBet, componentElement.dataset);
        break;
      case 'relacja':
        app = createApp(components.Commentary, componentElement.dataset);
        break;
      case 'kalkulator_typow':
        app = createApp(components.BetsCalculator, componentElement.dataset);
        break;
      case 'ostatnie_typy':
        app = createApp(components.LastBets, componentElement.dataset);
        break;
      case 'bonus_kontakt':
        app = createApp(components.BonusContactForm, componentElement.dataset);
        break;
      case 'blok_oceny_bukmachera':
        app = createApp(components.BookmakerRating, componentElement.dataset);
        break;
      case 'blok_przycisk_komentarze':
        app = createApp(components.ScrollToCommentsButton, componentElement.dataset);
        break;
      case 'blok-informacja':
        app = createApp(components.WorthKnowing, componentElement.dataset);
        break;
      case 'kursy':
        app = createApp(components.BettingOdds, componentElement.dataset);
        break;
      case 'stopka_reklama':
        app = createApp(components.AdFoot, componentElement.dataset);
        break;
      case 'karta_walk':
        app = createApp(components.FightCard, componentElement.dataset);
        break;
      case 'walki-zawodnika':
        app = createApp(components.FighterHistory, componentElement.dataset);
        break;
      case 'live-chat':
        app = createApp(components.LiveChat, componentElement.dataset);
        break;
      case 'krecz_tabela':
        app = createApp(components.KreczTabela, componentElement.dataset);
        break;
      case 'blok_box_niewidzialny':
        app = createApp(components.InvisibleBox, componentElement.dataset);
        break;
      case 'daily-bets-main-page':
        app = createApp(components.DailyBetsMainPage, componentElement.dataset);
        break;
      case 'niewidzialny_sporty_walki':
        app = createApp(components.MartialArtsInfo);
        break;
      case 'kod_promocyjny_box':
        app = createApp(components.PromoCodeBox, componentElement.dataset);
				break;
      case 'slownik_bukmacherski':
        app = createApp(components.BookmakerDictionary, componentElement.dataset);
        break;
      case 'kupony-graczy':
        app = createApp(components.WonCoupons, componentElement.dataset);
        break;
      case 'najblizsze-gale':
        app = createApp(components.UpcomingGalas, componentElement.dataset);
        break;
      case 'ocen_artykul':
        app = createApp(components.RateArticle, componentElement.dataset);
        break;
      case 'wybrane_oceny_bukmachera':
        app = createApp(components.SelectedFunctionalities, componentElement.dataset);
        break;
      case 'szczegoly_kodu_promocyjnego':
        app = createApp(components.PromoCodeDetails, componentElement.dataset);
        break;
      case 'football-stats-top':
        app = createApp(components.FootballStatsTop, componentElement.dataset);
        break;
      case 'godziny_otwarcia':
        app = createApp(components.OpeningHours, componentElement.dataset);
        break;
      case 'post-summary-mobile':
        app = createApp(components.PostSummaryMobile, componentElement.dataset);
        break;
      case 'post-summary-desktop':
        app = createApp(components.PostSummaryDesktop, componentElement.dataset);
        break;
	    case 'bookmaker-bonuses-tables':
				app = createApp(components.BookmakerBonusesTables, componentElement.dataset);
				break;
	    case 'football-scorers':
				app = createApp(components.FootballStatsScorers, componentElement.dataset);
				break;
	    case 'football-navigation':
				app = createApp(components.FootballStatsNavigation, componentElement.dataset);
				break;
      case 'football-stats-main':
        app = createApp(components.FootballStatsMain, componentElement.dataset);
        break;
      case 'stats-page':
        app = createApp(components.StatsPage, componentElement.dataset);
        break;
	    case 'football-stats-mobile-top-nav':
				app = createApp(components.FootballStatsMobileTopNav, componentElement.dataset);
				break;
	    case 'football-league-history':
				app = createApp(components.FootballLeagueHistory, componentElement.dataset);
				break;
	    case 'football-team':
				app = createApp(components.FootballTeam, componentElement.dataset);
				break;
      case 'gala-schedule':
				app = createApp(components.GalaSchedule, componentElement.dataset);
				break;
	    case 'football-stats-left-sidebar-search':
				app = createApp(components.FootballStatsLeftSidebarSearch, componentElement.dataset);
				break;
	    case 'h2h-overview-table':
				app = createApp(components.H2HOverviewTable, componentElement.dataset);
				break;
      case 'typy-football':
        app = createApp(components.FootballBets, componentElement.dataset);
        break;
	    case 'football-ladder':
				app = createApp(components.FootballLadder, componentElement.dataset);
				break;
	    case 'main-page-matches':
				app = createApp(components.MainPageMatches, componentElement.dataset);
				break;
	    case 'main-page-stats':
				app = createApp(components.MainPageStats, componentElement.dataset);
				break;
	    case 'country-matches':
				app = createApp(components.CountryMatches, componentElement.dataset);
				break;
	    case 'superbets-table':
				app = createApp(components.SuperBets, componentElement.dataset);
				break;
      case 'main-page-user-notifications':
        app = createApp(components.MainPageNotifications, componentElement.dataset);
        break;
	    case 'suggested-users-sidebar':
				app = createApp(components.SuggestedUsersSidebar, componentElement.dataset);
				break;
	    case 'payment-providers':
				app = createApp(components.PaymentProviders, componentElement.dataset);
				break;
	    case 'limited-time-bonuses':
		    app = createApp(components.LimitedTimeBonus, componentElement.dataset);
				break;
      case 'info-box':
        app = createApp(components.InfoBox, componentElement.dataset);
        break;
      case 'error-report':
        app = createApp(components.ErrorReport);
        break;
      default:
        console.log('No matching Vue component: ' + componentElement.dataset.component);
        continue;
    }
    window.mountApp(app, componentElement);
  }
};

window.mountApp = function mountApp(app, element) {
  app.use(VueAxios, axios);
  app.use(vClickOutside);
  app.use(pinia);
  app.config.globalProperties.window = window;
  app.config.globalProperties.DateTime = DateTime;
  app.config.globalProperties.Duration = Duration;
  app.config.globalProperties.Interval = Interval;
  app.config.globalProperties.emitter = emitter;
  app.config.globalProperties.removePlaceholder = function (identifier) {
    document.querySelectorAll(`.placeholder-${identifier}`).forEach(el => el.remove());
  };

  app.provide('WP', BACKEND_DATA.data);

  app.mount(element);
};

const pinia = createPinia();

let pageElements = document.querySelectorAll('[data-page]');
let sectionElements = document.querySelectorAll('[data-section]');
let componentElements = document.querySelectorAll('[data-component]');
window.createAndMountApp(pageElements, sectionElements, componentElements);

let localStorageSettings = localStorage.getItem('ZT_Settings');
const settingsStore = useSettingsStore();

if (localStorageSettings && !isLoggedIn()) {
  pinia.state.value.Settings = JSON.parse(localStorageSettings);
} else if (isLoggedIn()) {
  settingsStore.getUserSettings();
}

const userStore = useUserStore();
userStore.getFollowedUsersIds();
userStore.getSubscribedUsersIds();
userStore.getBlockedUsersIds();
userStore.getBlockingUsersIds();

const betStore = useBetStore();
const couponStore = useCouponStore();
const adminStore = useAdminStore();

watch(pinia.state.value.Settings, (state) => {
  if (!isLoggedIn()){
    localStorage.setItem('ZT_Settings', JSON.stringify(state));
  }
}, { deep: true });

window.addEventListener('click', function(event) {
  event.composedPath().some(element => {
    if (element instanceof Element) {
      let counterName = element.getAttribute('data-count-click');
      if (counterName) {
	      let categoryName = element.getAttribute('data-count-click-category');
        let counterPromise = countClicks(counterName, categoryName ?? 'general');
        if (element.nodeName === 'A') {
          event.preventDefault();
          counterPromise.then(() => {
            if (element.target === '_blank') {
              window.open(element.href, '_blank');
            } else {
              window.location = element.href;
            }
          });
        }
        return true;
      }
    }
  });
});

const copyToClipboard = () => {
	const range = window.getSelection().getRangeAt(0),
		rangeContents = range.cloneContents(),
		pageLink = `Czytaj więcej na ${ document.location.href }`,
		helper = document.createElement("div");

	helper.appendChild(rangeContents);

	event.clipboardData.setData("text/plain", `${ helper.innerText }\n${ pageLink }`);
	event.clipboardData.setData("text/html", `${ helper.innerHTML }<br>${ pageLink }`);
	event.preventDefault();
}

window.addEventListener('load', () => {
	document.querySelector('.post-content')?.addEventListener('copy', copyToClipboard);
	document.querySelector('.tekst')?.addEventListener('copy', copyToClipboard);

	if (window.size.mobile) return;

	const submenu = document.querySelectorAll('#menu-item-58218>.sub-menu>li>a');
	submenu.forEach(link => {
		link.setAttribute('data-count-click', 'Wszystkie gale w menu');
	});

	let next3DaysMatches = document.querySelector("#menu-item-196629 > a");
	next3DaysMatches?.setAttribute('data-count-click', 'Hamburger: Mecze - Najbliższe 3 dni');
});

if (window.location.pathname === '/football-stats/') {
  if ('scrollRestoration' in history) {
    history.scrollRestoration = 'manual';
  }

  window.addEventListener('beforeunload', () => {
    saveScrollPositionState(window.location.pathname);
  });
}